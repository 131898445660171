import React, { useEffect } from 'react';
import config from '../config';
import '../styles.css';

const GoogleAds = () => {
  useEffect(() => {
    const loadAdsScript = () => {
      const script = document.createElement('script');
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      script.async = true;
      script.dataset.adClient = config.googleAds.client;
      script.onload = () => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      };
      document.head.appendChild(script);
    };

    loadAdsScript();

    return () => {
      const adsScript = document.querySelector('script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"]');
      if (adsScript) {
        document.head.removeChild(adsScript);
      }
    };
  }, []);

  return (
    <div className="google-ads-container">
      <ins className="adsbygoogle"
           style={{ display: 'block', width: '100%', height: '90px', minWidth: '340px', maxWidth: '970px' }}
           data-ad-client={config.googleAds.client}
           data-ad-slot={config.googleAds.slot}
           ></ins>
    </div>
  );
};

export default GoogleAds;
