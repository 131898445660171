// src/backgrounds.js
const backgrounds = {
    large: [
      'images/landscape_1_large.jpg',
      // Add more large images
    ],
    medium: [
      'images/landscape_1_medium.jpg',
      // Add more medium images
    ],
    small: [
      'images/landscape_1_medium.jpg',
      // Add more small images
    ],
    mobile: [
      'images/landscape_1_medium.jpg',
      // Add more mobile images
    ],
  };
  
  const getRandomBackground = (size) => {
    const backgroundSet = backgrounds[size];
    const randomIndex = Math.floor(Math.random() * backgroundSet.length);
    return backgroundSet[randomIndex];
  };
  
  export default getRandomBackground;
  