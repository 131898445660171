import React, { useEffect, useState } from 'react';
import { Container, Box, CssBaseline, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import IPDisplay from './components/IPDisplay';
import PrivacyOverlay from './components/PrivacyOverlay';
import GoogleAds from './components/GoogleAds';
import { lightTheme, darkTheme } from './themes/theme';
import getRandomBackground from './backgrounds';
import './styles.css';

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [background, setBackground] = useState('');
  const [privacyOpen, setPrivacyOpen] = useState(false);

  useEffect(() => {
    const savedTheme = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedTheme);
  }, []);

  useEffect(() => {
    const determineBackgroundSize = () => {
      const width = window.innerWidth;

      if (width >= 1920) {
        return 'large';
      } else if (width >= 1280) {
        return 'medium';
      } else if (width >= 720) {
        return 'small';
      } else {
        return 'mobile';
      }
    };

    const backgroundSize = determineBackgroundSize();
    const randomBackground = getRandomBackground(backgroundSize);
    setBackground(randomBackground);
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      localStorage.setItem('darkMode', !prevMode);
      return !prevMode;
    });
  };

  const handlePrivacyOpen = () => {
    setPrivacyOpen(true);
  };

  const handlePrivacyClose = () => {
    setPrivacyOpen(false);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <div className="background" style={{ backgroundImage: `url(${background})` }}>
        <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <Container 
          maxWidth="sm" 
          className="main-container"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            minHeight="100vh"
            sx={{ paddingTop: '40px' }} // Adjust this value to match the height of your header
          >
            <IPDisplay />
            <GoogleAds />
          </Box>
        </Container>
        <Button variant="contained" color="primary" className="privacy-button" onClick={handlePrivacyOpen}>
          Privacy
        </Button>
        <PrivacyOverlay open={privacyOpen} handleClose={handlePrivacyClose} />
      </div>
    </ThemeProvider>
  );
}

export default App;
