import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Box, Switch } from '@mui/material';
import { format } from 'date-fns';
import { LocationOn, Thermostat, AccessTime, WbSunny, Grain, AcUnit, Opacity, Cloud, FlashOn, DateRange } from '@mui/icons-material';
import config from '../config';
import '../styles.css';

import logoLight from '../assets/logo-light.webp';
import logoDark from '../assets/logo-dark.webp';

const Header = ({ darkMode, toggleDarkMode }) => {
  const [dateTime, setDateTime] = useState(new Date());
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Update the time every second
    const timer = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    // Fetch user's location based on IP
    axios.get(config.IPAPI_BASE_URL)
      .then(response => {
        setLocation(response.data.city);
        // Fetch weather information based on location
        return axios.get(`${config.OPENWEATHER_BASE_URL}?q=${response.data.city}&units=metric&appid=${config.OPENWEATHER_API_KEY}`);
      })
      .then(response => {
        setWeather(response.data);
      })
      .catch(error => console.error('Error fetching location or weather:', error));

    // Update screen width on resize
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatTemperature = (tempC) => `${tempC.toFixed(1)}°C / ${(tempC * 9/5 + 32).toFixed(1)}°F`;

  const getWeatherIcon = (main) => {
    switch (main) {
      case 'Clear':
        return <WbSunny />;
      case 'Clouds':
        return <Cloud />;
      case 'Rain':
        return <Opacity />;
      case 'Snow':
        return <AcUnit />;
      case 'Drizzle':
        return <Grain />;
      case 'Thunderstorm':
        return <FlashOn />;
      default:
        return <WbSunny />;
    }
  };

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const isComputerScreen = screenWidth >= 1024;

  return (
    <AppBar className="header-bar">
      <Toolbar>
        <Box className="logo-section">
          <img src={darkMode ? logoDark : logoLight} alt="IP View Logo" className="logo-image" />
        </Box>
        <Box className="info-section">
          {isComputerScreen && weather && (
            <>
              <Box className="info-item">
                <LocationOn />
                <Typography variant="body1" className="info-text">
                  {location}
                </Typography>
              </Box>
              <Box className="info-item">
                {getWeatherIcon(weather.weather[0].main)}
                <Typography variant="body1" className="info-text">
                  {weather.weather[0].description}
                </Typography>
              </Box>
              <Box className="info-item">
                <Thermostat />
                <Typography variant="body1" className="info-text">
                  {formatTemperature(weather.main.temp)}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" className="info-item">
                <DateRange />
                <Typography variant="body1" className="info-text">
                  {format(dateTime, 'PPP')}
                </Typography>
              </Box>
            </>
          )}
          <Box className="info-item">
            <AccessTime />
            <Typography variant="body1" className="info-text">
              {format(dateTime, 'HH:mm:ss')}
            </Typography>
          </Box>
          <Switch checked={darkMode} onChange={toggleDarkMode} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
