// src/PrivacyOverlay.js
import React from 'react';
import { Typography, Button } from '@mui/material';
import privacyPolicy from './PrivacyPolicy.js';
import '../styles.css';

const PrivacyOverlay = ({ open, handleClose }) => {
  if (!open) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  return (
    <div className="overlay" onClick={handleOverlayClick}>
      <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
        <Typography variant="h4">Privacy Policy</Typography>
        <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default PrivacyOverlay;
