import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Typography, IconButton, Paper, Button } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import '../styles.css';

const IPDisplay = () => {
  const [publicIP, setPublicIP] = useState('');
  const [geoInfo, setGeoInfo] = useState({});
  const [screenResolution, setScreenResolution] = useState('');
  const [osInfo, setOsInfo] = useState('');
  const [browserInfo, setBrowserInfo] = useState('');
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Fetch Public IP Address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        const ip = response.data.ip;
        setPublicIP(ip);

        // Fetch Geo Information based on IP
        return axios.get(`https://ipapi.co/${ip}/json/`);
      })
      .then(response => {
        setGeoInfo(response.data);
      })
      .catch(error => console.error('Error fetching public IP or geo info:', error));

    // Get Screen Resolution
    setScreenResolution(`${window.screen.width}x${window.screen.height}`);

    // Get OS and Browser Info
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const os = platform.includes('Win') ? 'Windows' : platform.includes('Mac') ? 'MacOS' : platform.includes('Linux') ? 'Linux' : 'Other';
    const browser = userAgent.includes('Chrome') ? 'Chrome' : userAgent.includes('Firefox') ? 'Firefox' : userAgent.includes('Safari') ? 'Safari' : 'Other';
    
    setOsInfo(os);
    setBrowserInfo(browser);
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const toggleMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo);
  };

  return (
    <Paper className="ip-info-container">
      <Typography variant="h5">🛜 IP & System Information</Typography>
      <Box className="ip-info-box">
        <Typography>
          <b>Your Public IP:</b> {publicIP}
        </Typography>
        <Box display="flex" alignItems="center">
          {copied && <Typography className="copied-message">Copied</Typography>}
          <IconButton className="copy-button" onClick={() => copyToClipboard(publicIP)} title="Copy to clipboard">
            <ContentCopy />
          </IconButton>
        </Box>
      </Box>
      <Box className="ip-info-box">
      <Typography>
          <b>Network:</b> {geoInfo.network}
        </Typography>
      </Box>
      <Box className="ip-info-box">
      <Typography>
          <b>ISP:</b> {geoInfo.org}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        className="more-info-button"
        onClick={toggleMoreInfo}
      >
        {showMoreInfo ? 'Less information' : 'More information'}
      </Button>
      <Box className={`more-info-content ${showMoreInfo ? 'visible' : ''}`}>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Country:</b> {geoInfo.country_name} ({geoInfo.country})</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Region:</b> {geoInfo.region}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>City:</b> {geoInfo.city}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Zip:</b> {geoInfo.postal}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Latitude/Longitude:</b> {geoInfo.latitude} / {geoInfo.longitude}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Timezone:</b> {geoInfo.utc_offset}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Screen Resolution:</b> {screenResolution}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Computer:</b> {osInfo}</Typography>
        </Box>
        <Box className="ip-info-box">
          <Typography variant="body1"><b>Browser:</b> {browserInfo}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default IPDisplay;
