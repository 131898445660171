// src/privacyPolicy.js
const privacyPolicy = `
  <p>
    This website is committed to protecting your privacy. We do not store any personally identifiable information about our users. 
    The following outlines our privacy policy:
  </p>
  <h3>Information We Collect</h3>
  <p>
    We collect the following information to make the site work:
  </p>
  <ul>
    <li>Your public IP address to display it on the website.</li>
    <li>Geolocation information based on your IP address to display weather and location information.</li>
  </ul>
  <h3>Information We Do Not Collect</h3>
  <p>
    We do not collect or store any of the following information:
  </p>
  <ul>
    <li>Personal details such as name, email address, or phone number.</li>
    <li>Your browsing history or any other personal data.</li>
    <li>Your IP address or any other personal information for longer than the session duration.</li>
  </ul>
  <h3>Use of Information</h3>
  <p>
    The information collected is used solely for the purpose of providing you with the services on this website, 
    such as displaying your public IP address and local weather information.
  </p>
  <h3>Cookies</h3>
  <p>
    This website does not use cookies to track users.
  </p>
  <h3>Third-Party Services</h3>
  <p>
    We use third-party services like OpenWeatherMap and IPAPI to fetch weather and location information. 
    These services may have their own privacy policies and may collect data as described in their respective privacy policies.
  </p>
  <h3>Changes to This Policy</h3>
  <p>
    We may update this privacy policy from time to time. Any changes will be posted on this page.
  </p>
  <p>
    If you have any questions about this privacy policy, please contact us.
  </p>
`;

export default privacyPolicy;
