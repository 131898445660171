// src/config.js
const config = {
    OPENWEATHER_API_KEY: 'adc178f6b01771daae020b7e57ac2466',
    IPAPI_BASE_URL: 'https://ipapi.co/json/',
    OPENWEATHER_BASE_URL: 'https://api.openweathermap.org/data/2.5/weather',
    googleAds: {
      client: 'ca-pub-1177088220817928',
      slot: '9959922247', // Replace this with your actual ad slot ID
    },
  };
  
  export default config;